import { FC, ReactNode, useState } from 'react';
import X from 'react-feather/dist/icons/x';
import cx from 'classnames';
import { Box, FormGroup, Select } from 'tombac';
import { DateIcon, OneDateTimeIcon, TimeIcon } from 'tombac-icons';
import { FormattedTimeRange } from 'components/AnalysisNew/TimeRange/FormattedTimeRange';
import { Scenario } from 'components/AnalysisViewPage/MapFlowsPro/logic/scenario';
import { Analysis } from 'model/AnalysisDto';
import MapAsideSection from '../MapAside/MapAsideSection';
import { SelectSwitcher } from './SelectSwitcher';
import { dateRangeFromDto } from 'components/AnalysisNew/DateRanges/DateRange';
import { ExclusionsTooltip } from 'components/AnalysisViewPage/Map/ExclusionsTooltip';
import { useSettings } from 'user';
import { getRangeName } from 'components/AnalysisNew/DateRanges/DateUtils';
import './DateTimeSelector.css';

interface Option {
  value: number;
  label: ReactNode;
}

type Switcher = 'DATE' | 'TIME' | 'NONE' | 'CMP_DATE' | 'CMP_TIME';
interface Props {
  scenario: Scenario;
  onChange: (k: Scenario) => void;
  analysis: Analysis;
  comparable?: boolean;
  asideSection?: boolean;
  map?: boolean;
  column?: boolean;
  row?: boolean;
}

export const DateTimeSelector: FC<Props> = (props) => {
  const { map } = props;
  const dateFormat: string = useSettings().dateFormat;
  const { dateRanges, timeRanges } = props.analysis.info.timeDefinition;
  const [activeSwitcher, setActiveSwitcher] = useState<Switcher>('NONE');
  const [hoveredSwitcher, setHoveredSwitcher] = useState<Switcher>('NONE');

  const canCompare =
    props.comparable && (timeRanges.length > 1 || dateRanges.length > 1);

  const enableCompare = () => {
    props.onChange({
      ...props.scenario,
      dateRangeCompare:
        dateRanges
          .map((_, i) => i)
          .filter((x) => x !== props.scenario.dateRange)[0] || 0,
      timeRangeCompare:
        timeRanges
          .map((_, i) => i)
          .filter((x) => x !== props.scenario.dateRange)[0] || 0,
    });
  };

  const disableCompare = () => {
    props.onChange({
      ...props.scenario,
      dateRangeCompare: undefined,
      timeRangeCompare: undefined,
    });
  };

  const className = cx('DateTimeSelector', {
    'DateTimeSelector--map': map,
    'DateTimeSelector--column': props.column,
  });

  const menuPlacement = map ? 'top' : 'bottom';

  const changeDateRange = (val: any) => {
    const dateRange = Number(val);
    props.onChange({ ...props.scenario, dateRange });
  };

  const changeTimeRange = (val: any) => {
    const timeRange = Number(val);
    props.onChange({ ...props.scenario, timeRange });
  };

  const changeCompareDateRange = (val: any) => {
    const dateRangeCompare = Number(val);
    props.onChange({ ...props.scenario, dateRangeCompare });
  };

  const changeCompareTimeRange = (val: any) => {
    const timeRangeCompare = Number(val);
    props.onChange({ ...props.scenario, timeRangeCompare });
  };

  const createMouseEvents = (type: Switcher) => ({
    onMouseEnter: () => setHoveredSwitcher(type),
    onMouseLeave: () => setHoveredSwitcher('NONE'),
  });

  const dateRangeOptions: Option[] = dateRanges.map((it, i) => ({
    label: (
      <Box $display="flex" $gap="2px" $alignItems="center">
        {getRangeName(it, dateFormat)}
        {(it.exclusions ?? [])?.length > 0 && (
          <ExclusionsTooltip dateRange={dateRangeFromDto(it)} />
        )}
      </Box>
    ),
    value: i,
  }));

  const timeRangeOptions: Option[] = timeRanges.map((it, i) => ({
    label: FormattedTimeRange({ timeRange: it }),
    value: i,
  }));

  const dateRangeCompareOptions: Option[] = dateRanges.map((it, i) => ({
    label: (
      <Box $display="flex" $gap="2px" $alignItems="center">
        {getRangeName(it, dateFormat)}
        {(it.exclusions ?? []).length > 0 && (
          <ExclusionsTooltip dateRange={dateRangeFromDto(it)} />
        )}
      </Box>
    ),
    value: i,
  }));

  const timeRangeCompareOptions: Option[] = timeRanges.map((it, i) => ({
    label: FormattedTimeRange({ timeRange: it }),
    value: i,
  }));

  const content = (
    <div className={className}>
      <div className="DateTimeSelector__range-container">
        <div
          className="DateTimeSelector__range-inputs"
          style={{ flexDirection: props.row ? 'row' : undefined }}
        >
          <div {...createMouseEvents('DATE')} style={{ marginBottom: 8 }}>
            <FormGroup
              label={
                <SelectSwitcher
                  label="Date"
                  currentValue={props.scenario.dateRange}
                  changeValue={changeDateRange}
                  maxValue={dateRanges.length - 1}
                  canSwitchByKeyboard={activeSwitcher === 'DATE'}
                  onFocus={() => setActiveSwitcher('DATE')}
                  showArrows={hoveredSwitcher === 'DATE'}
                />
              }
            >
              <Select<Option>
                onChange={(option: Option) => changeDateRange(option.value)}
                $width="260px"
                menuPlacement={menuPlacement}
                formatOptionLabel={({ label }, { context }) =>
                  context === 'menu' ? (
                    label
                  ) : (
                    <Box
                      $display="grid"
                      $gridTemplateColumns="min-content 1fr"
                      $gridColumnGap="4px"
                    >
                      <DateIcon /> {label}
                    </Box>
                  )
                }
                value={dateRangeOptions.find(
                  (o) => o.value === props.scenario.dateRange,
                )}
                options={dateRangeOptions}
              />
            </FormGroup>
          </div>
          <div {...createMouseEvents('TIME')}>
            <FormGroup
              label={
                <SelectSwitcher
                  label="Time"
                  currentValue={props.scenario.timeRange}
                  changeValue={changeTimeRange}
                  maxValue={timeRanges.length - 1}
                  canSwitchByKeyboard={activeSwitcher === 'TIME'}
                  onFocus={() => setActiveSwitcher('TIME')}
                  showArrows={hoveredSwitcher === 'TIME'}
                />
              }
            >
              <Select<Option>
                onChange={(option: Option) => changeTimeRange(option.value)}
                $width={props.column ? '260px' : '190px'}
                value={timeRangeOptions.find(
                  (o) => o.value === props.scenario.timeRange,
                )}
                menuPlacement={menuPlacement}
                formatOptionLabel={({ label }, { context }) =>
                  context === 'menu' ? (
                    label
                  ) : (
                    <>
                      <TimeIcon /> {label}
                    </>
                  )
                }
                options={timeRangeOptions}
              />
            </FormGroup>
          </div>
        </div>
      </div>

      {props.scenario.dateRangeCompare !== undefined && (
        <div className="DateTimeSelector__range-container DateTimeSelector__range-container--compare">
          <div
            className="DateTimeSelector__range-close"
            onClick={disableCompare}
          >
            <X size={16} />
          </div>
          <div className="DateTimeSelector__range-title">
            Compare time range
          </div>
          <div className="DateTimeSelector__range-inputs">
            <div {...createMouseEvents('CMP_DATE')}>
              <FormGroup
                label={
                  <SelectSwitcher
                    label="Date"
                    currentValue={props.scenario.dateRangeCompare}
                    changeValue={changeCompareDateRange}
                    maxValue={dateRanges.length - 1}
                    canSwitchByKeyboard={activeSwitcher === 'CMP_DATE'}
                    onFocus={() => setActiveSwitcher('CMP_DATE')}
                    showArrows={hoveredSwitcher === 'CMP_DATE'}
                  />
                }
              >
                <Select<Option>
                  onChange={(option: Option) =>
                    props.onChange({
                      ...props.scenario,
                      dateRangeCompare: Number(option.value),
                    })
                  }
                  $width="240px"
                  value={dateRangeCompareOptions.find(
                    (o) => o.value === props.scenario.dateRangeCompare,
                  )}
                  options={dateRangeCompareOptions}
                  menuPlacement={menuPlacement}
                  formatOptionLabel={({ label }, { context }) =>
                    context === 'menu' ? (
                      label
                    ) : (
                      <Box
                        $display="grid"
                        $gridTemplateColumns="min-content 1fr"
                        $gridColumnGap="4px"
                      >
                        <DateIcon /> {label}
                      </Box>
                    )
                  }
                />
              </FormGroup>
            </div>
            <div {...createMouseEvents('CMP_TIME')}>
              <FormGroup
                label={
                  <SelectSwitcher
                    label="Time"
                    currentValue={props.scenario.timeRangeCompare}
                    changeValue={changeCompareTimeRange}
                    maxValue={timeRanges.length - 1}
                    canSwitchByKeyboard={activeSwitcher === 'CMP_TIME'}
                    onFocus={() => setActiveSwitcher('CMP_TIME')}
                    showArrows={hoveredSwitcher === 'CMP_TIME'}
                  />
                }
              >
                <Select<Option>
                  onChange={(option: Option) =>
                    props.onChange({
                      ...props.scenario,
                      timeRangeCompare: Number(option.value),
                    })
                  }
                  $width="240px"
                  value={timeRangeCompareOptions.find(
                    (o) => o.value === props.scenario.timeRangeCompare,
                  )}
                  options={timeRangeCompareOptions}
                  menuPlacement={menuPlacement}
                  formatOptionLabel={({ label }, { context }) =>
                    context === 'menu' ? (
                      label
                    ) : (
                      <>
                        <TimeIcon /> {label}
                      </>
                    )
                  }
                />
              </FormGroup>
            </div>
          </div>
        </div>
      )}
      {props.scenario.dateRangeCompare === undefined && canCompare && (
        <div
          onClick={enableCompare}
          className="DateTimeSelector__compare-button"
        >
          + Compare
        </div>
      )}
    </div>
  );

  return props.asideSection ? (
    <MapAsideSection
      title="Date & time"
      defaultHide={false}
      icon={<OneDateTimeIcon />}
    >
      {content}
    </MapAsideSection>
  ) : (
    content
  );
};

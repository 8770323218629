import AnalysisApi from 'api/AnalysisApi';
import { DateTimeSelector } from 'components/UI/DateTimeSelector/DateTimeSelector';
import {
  HeatMapLine,
  MapAside,
  MapAsideSection,
  ProgressBar,
} from 'components/UI/UI';
import Immutable from 'immutable';
import {
  downloadCSV,
  downloadExcel,
  downloadExcelWithAllResults,
  downloadMultipleCSV,
} from 'logic/colored-matrix/exportFile';
import { useMemo, useState } from 'react';
import Warning from 'react-feather/dist/icons/alert-triangle';
import {
  Box,
  Button,
  Label,
  RadioGroup,
  RadioGroupChangeHandler,
} from 'tombac';
import { useAnalysisContext } from '../AnalysisViewPage';
import { PartialResult } from '../MapFlowsPro/logic/PartialResult';
import { Scenario, useScenario } from '../MapFlowsPro/logic/scenario';
import { ViewPageNavbar } from '../NavBar/ViewPageNavbar';
import ViewPageContent from '../ViewPageContent/ViewPageContent';
import './ColoredMatrix.css';
import { MatrixValuesType, RadioOptions } from './ColoredMatrix.types';
import { transformMatrix } from './ColoredMatrixTransform';
import Excel from './icons/Excel';
import Matrix from './Matrix';
import RegionSelector from './RegionSelector';
import ThroughRegionSelect from './ThroughRegionSelect';
import { useIsAdmin } from 'user';
import { buildFlowMatrixTripsDebugLink } from 'logic/debug/tripsDebugLink';

interface State {
  viaRegion?: number | null;
  sortDirection: boolean;
  sortRegion?: number;
  rotate: boolean;
  selectedCols: string[];
  selectedRows: string[];
}
const radioOptions: RadioOptions[] = [
  { label: 'Trips', value: 'ABSOLUTE' },
  { label: 'Percent', value: 'RELATIVE' },
];

function ColoredMatrix() {
  const [matrixValuesType, setMatrixValuesType] = useState<MatrixValuesType>(
    'ABSOLUTE',
  );
  const [clickedExportButton, setClickedExportButton] = useState('');
  const [progress, setProgress] = useState(0);
  const isAdmin = useIsAdmin();

  const { analysis } = useAnalysisContext();
  const regionLabels = useMemo(
    () => analysis.regions.map((it) => it.properties.name),
    [analysis.regions],
  );
  const hasExternals = analysis.info.passMatrix !== true;
  const [state, setState] = useState<State>({
    viaRegion: null,
    sortDirection: false,
    sortRegion: undefined,
    rotate: false,
    selectedCols: regionLabels,
    selectedRows: regionLabels,
  });

  const allRegionLabels = hasExternals
    ? [...regionLabels, 'External']
    : regionLabels;

  const allRegions = useMemo(() => {
    const regionIds = analysis.regions.map((_, i) => i);
    return hasExternals ? [...regionIds, analysis.regions.length] : regionIds;
  }, [analysis.regions, hasExternals]);

  const handleColumnClick = (index: number) => {
    if (index === state.sortRegion) {
      if (state.sortDirection) {
        setState((prev) => ({ ...prev, sortDirection: false }));
      } else {
        setState((prev) => ({ ...prev, sortRegion: undefined }));
      }
    } else {
      setState((prev) => ({ ...prev, sortRegion: index }));
      setState((prev) => ({ ...prev, sortDirection: true }));
    }
  };

  const handleCellClick = (origin: number, destination: number) => {
    const link = buildFlowMatrixTripsDebugLink(
      analysis.info.id,
      origin,
      destination,
      viaRegion ?? origin,
      scenario.timeRange,
      scenario.dateRange,
    );
    window.open(link, '_blank');
  };

  const {
    selectedCols,
    selectedRows,
    rotate,
    sortRegion,
    sortDirection,
    viaRegion,
  } = state;

  const { scenario, setScenario } = useScenario();

  const scenarioWithRegions: Scenario = useMemo(
    () => ({
      ...scenario,
      origins: Immutable.Set(allRegions),
      destinations: Immutable.Set(allRegions),
      vias: Immutable.Set(state.viaRegion !== null ? [state.viaRegion] : []),
    }),
    [viaRegion, scenario],
  );

  const { result, loading } = PartialResult.use(analysis, scenarioWithRegions);

  let transformed: any;
  if (result) {
    transformed = transformMatrix({
      result,
      regionLabels: allRegionLabels,
      viaRegion,
      sortDirection,
      sortRegion,
      rotate,
      selectedCols,
      selectedRows,
      matrixValuesType,
    });
    transformed.dateRange = [result.meta().dateRange];
    transformed.timeRange = [result.meta().timeRange];
  }

  const emptySelection = selectedCols.length === 0 || selectedRows.length === 0;

  const someRegionsAreHidden =
    allRegionLabels.length !== selectedCols.length ||
    allRegionLabels.length !== selectedRows.length;

  const collectAllResults = async () => {
    const { dateRanges, timeRanges } = analysis.info.timeDefinition;

    const combinedResults: any = {};
    combinedResults.transformedMatrix = [[[]]];
    combinedResults.regionLabels = allRegionLabels;
    combinedResults.header = allRegionLabels.map((it, index) => ({
      name: it,
      index,
    }));
    combinedResults.rotate = false;
    combinedResults.dateRange = [];
    combinedResults.timeRange = [];

    // declaration of results array in order to populate it with actual results later
    const resultsArray: { trips: number; percents: number }[][][] = new Array(
      allRegionLabels.length,
    )
      .fill(-1)
      .map(() =>
        new Array(allRegionLabels.length)
          .fill(-1)
          .map(() => new Array(dateRanges.length * timeRanges.length).fill(-1)),
      );
    let resultsIndex = 0;

    const numberOfIterations = dateRanges.length * timeRanges.length;

    for (const date of dateRanges) {
      for (const time of timeRanges) {
        const request = async (resultsIndex: number) => {
          const singleResult = await AnalysisApi.getFullResult(
            analysis,
            date,
            time,
          );

          const sumOfTrips = singleResult.sumOfTrips(
            allRegionLabels.length,
            state.viaRegion,
          );

          combinedResults.dateRange[resultsIndex] = date;
          combinedResults.timeRange[resultsIndex] = time;
          for (let origins = 0; origins < allRegionLabels.length; origins++) {
            for (
              let destinations = 0;
              destinations < allRegionLabels.length;
              destinations++
            ) {
              const { trips } = singleResult.get(
                origins,
                destinations,
                state.viaRegion,
              );
              resultsArray[origins][destinations][resultsIndex] = {
                trips,
                percents: Number(((trips / sumOfTrips) * 100).toFixed(3)),
              };
            }
          }
        };
        await request(resultsIndex);

        resultsIndex = resultsIndex + 1;
        setProgress(Math.ceil((resultsIndex / numberOfIterations) * 100));
      }
    }
    combinedResults.transformedMatrix = resultsArray.map(
      (results, arrayIndex: number) => ({
        array: results,
        index: arrayIndex,
      }),
    );

    setProgress(0);
    setClickedExportButton('');
    return combinedResults;
  };

  const handleRadioChange: RadioGroupChangeHandler<RadioOptions> = ({
    value,
  }) => {
    setMatrixValuesType(value);
  };

  return (
    <>
      <ViewPageNavbar visualisationName="Colored Matrix" />
      <ViewPageContent style={{ background: '#FFF' }}>
        <MapAside width={'300px'}>
          <MapAsideSection
            title="Regions"
            variant={someRegionsAreHidden ? 'info' : undefined}
            defaultHide={false}
          >
            {someRegionsAreHidden && (
              <div className="colored-matrix-parameters-warning">
                <Warning size={16} /> Some regions are hidden
              </div>
            )}
            <RegionSelector
              names={allRegionLabels}
              selectedCols={state.selectedCols}
              selectedRows={state.selectedRows}
              onChange={(colsAndRows: any) =>
                setState((prev) => ({ ...prev, ...colsAndRows }))
              }
            />
          </MapAsideSection>
          <MapAsideSection title="Via region">
            <ThroughRegionSelect
              regionLabels={regionLabels}
              sliceIndex={viaRegion}
              changeSlice={(index?: number | null) =>
                setState((prev) => ({ ...prev, viaRegion: index }))
              }
            />
            {viaRegion !== null && (
              <div style={{ margin: '10px 0' }}>
                <Button
                  onClick={() =>
                    setState((prev) => ({ ...prev, viaRegion: null }))
                  }
                >
                  Clear
                </Button>
              </div>
            )}
          </MapAsideSection>
          <DateTimeSelector
            column
            asideSection
            scenario={scenario}
            analysis={analysis}
            onChange={setScenario}
          />
          <MapAsideSection title="Values">
            <RadioGroup
              options={radioOptions}
              variant="horizontal"
              value={radioOptions.find(
                ({ value }) => value === matrixValuesType,
              )}
              onChange={handleRadioChange}
            />
          </MapAsideSection>
          <MapAsideSection title="Export">
            <div>Export data from current view</div>
            <div
              style={{
                display: 'flex',
                marginBottom: '12px',
                marginTop: '8px',
              }}
            >
              <div style={{ paddingRight: '16px' }}>
                <Button
                  disabled={!!clickedExportButton}
                  onClick={() =>
                    downloadExcel(transformed, analysis.info, matrixValuesType)
                  }
                  size="s"
                  prepend={<Excel size={22} />}
                >
                  XLS
                </Button>
              </div>
              <Button
                disabled={!!clickedExportButton}
                onClick={() =>
                  downloadCSV(transformed, analysis.info, matrixValuesType)
                }
                size="s"
                prepend={<Excel size={22} />}
              >
                CSV
              </Button>
            </div>
            <div>Export all the flows in all date and time ranges</div>
            <div style={{ display: 'flex', marginTop: '8px' }}>
              <div style={{ paddingRight: '16px' }}>
                <Button
                  busy={clickedExportButton === 'XLS'}
                  disabled={
                    !!clickedExportButton && clickedExportButton !== 'XLS'
                  }
                  onClick={async () => {
                    setClickedExportButton('XLS');
                    downloadExcelWithAllResults(
                      await collectAllResults(),
                      analysis.info,
                    );
                  }}
                  size="s"
                  prepend={<Excel size={22} />}
                >
                  XLS
                </Button>
              </div>
              <Button
                busy={clickedExportButton === 'CSV'}
                disabled={
                  !!clickedExportButton && clickedExportButton !== 'CSV'
                }
                onClick={async () => {
                  setClickedExportButton('CSV');
                  downloadMultipleCSV(await collectAllResults(), analysis.info);
                }}
                size="s"
                prepend={<Excel size={22} />}
              >
                CSV
              </Button>
            </div>
            {!!clickedExportButton && (
              <Box $mt="12px">
                <Label>Export progress: </Label>
                <Box $display="flex" $gap="6px" $alignItems="center">
                  <ProgressBar percentage={progress} disableAnimation />
                  <Box>{progress}%</Box>
                </Box>
              </Box>
            )}
          </MapAsideSection>
          <MapAsideSection title="Legend" defaultHide={false}>
            <HeatMapLine block={true} min={undefined} max={undefined} />
          </MapAsideSection>
        </MapAside>
        {emptySelection && (
          <div className="ColoredMatrix__empty-selection">
            <h2>Not enough regions are selected</h2>
            {selectedRows.length === 0 && 'No matrix rows are selected.'}
            {selectedCols.length === 0 && 'No matrix columns are selected.'}
            <p>To select regions click 'Select regions' button.</p>
          </div>
        )}

        {transformed && !emptySelection && (
          <Matrix
            loading={loading}
            matrix={transformed}
            matrixValuesType={matrixValuesType}
            onRotate={() => {
              setState((prev) => ({ ...prev, rotate: !prev.rotate }));
            }}
            handleColumnClick={handleColumnClick}
            handleCellClick={
              isAdmin && analysis.info.tripsDebug ? handleCellClick : undefined
            }
          />
        )}
      </ViewPageContent>
    </>
  );
}

export default ColoredMatrix;

import ResultSpinner from 'components/UI/ResultSpinner/ResultSpinner';
import Immutable from 'immutable';
import { ReadableResult } from 'logic/ReadableResult';
import { useMemo, useRef, useState } from 'react';
import { Button, Checkbox, Input } from 'tombac';
import { getLabel } from '../../../logic/colored-matrix/flowUtils';
import { DateTimeSelector } from '../../UI/DateTimeSelector/DateTimeSelector';
import { MapAside, MapAsideSection } from '../../UI/UI';
import { useAnalysisContext } from '../AnalysisViewPage';
import ThroughRegionSelect from '../ColoredMatrix/ThroughRegionSelect';
import { PartialResult } from '../MapFlowsPro/logic/PartialResult';
import { Scenario, useScenario } from '../MapFlowsPro/logic/scenario';
import { ViewPageNavbar } from '../NavBar/ViewPageNavbar';
import ViewPageContent from '../ViewPageContent/ViewPageContent';
import SankeyChart from './SankeyChart';
import './SankeyDiagram.css';
import SankeySearch from './SankeySearch';

const makeMatrix = (
  result: ReadableResult,
  size: number,
  viaRegion?: number,
) => {
  const matrix: number[][] = [];
  for (let o = 0; o < size; o++) {
    matrix[o] = [];
    for (let d = 0; d < size; d++) {
      matrix[o][d] = result.get(o, d, viaRegion).trips;
    }
  }
  return matrix;
};

interface SankeyDiagramState {
  flowLimit: number;
  displayRegions: any[];
  allowedFrom: number[];
  allowedTo: number[];
  sliceIndex: number;
  windowIndex: number;
  showExternals: boolean;
  showInternals: boolean;
}

function SankeyDiagram() {
  const { analysis } = useAnalysisContext();

  const [state, setState] = useState<SankeyDiagramState>({
    flowLimit: 20,
    displayRegions: [],
    allowedFrom: [],
    allowedTo: [],
    sliceIndex: null,
    windowIndex: 0,
    showExternals: false,
    showInternals: false,
  });
  const sortedRegionsInfo = useRef<any>([]);
  const flowInput = useRef<any>([]);
  const initialRegionsSet = useRef<boolean>(false);
  const regionLabels = useMemo(
    () => analysis.regions.map((it) => it.properties.name),
    [analysis.regions],
  );
  const hasExternals = analysis.info.passMatrix !== true;

  const getChartLabel = (index: number, length: any, isOrigin: boolean) => {
    const rootLabel = getLabel(regionLabels, index);
    const uniquePrefix = isOrigin ? `${index + 1}.` : ` ${index + 1}.`;
    return `${uniquePrefix} ${rootLabel}`;
  };

  const isVisible = (
    fromIndex: number,
    toIndex: number,
    fromLength: number,
    toLength: number,
  ) => {
    if (hasExternals && !state.showExternals) {
      if (fromIndex === fromLength - 1 || toIndex === toLength - 1) {
        return false;
      }
    }
    if (!state.showInternals) {
      if (fromIndex === toIndex) {
        return false;
      }
    }
    return true;
  };

  const generateChartRows = (result: ReadableResult) => {
    const { sliceIndex } = state;
    if (!result) {
      return;
    }
    const size = hasExternals ? regionLabels.length + 1 : regionLabels.length;
    const flowSlice = makeMatrix(result, size, sliceIndex);

    const fromLength = flowSlice.length;
    const toLength = fromLength > 0 ? flowSlice[0].length : 0;
    const chartRows: any = [];
    const { flowLimit } = state;
    const regionsInfoMap = {};
    flowSlice.forEach(
      (
        row: { forEach: (arg0: (flows: any, toIx: any) => void) => void },
        fromIx: number,
      ) => {
        row.forEach((flows: number, toIx: number) => {
          if (isVisible(fromIx, toIx, fromLength, toLength) && flows > 10) {
            if (!regionsInfoMap[fromIx]) {
              regionsInfoMap[fromIx] = {
                index: fromIx,
                fromFlows: 0,
                toFlows: 0,
              };
            }
            regionsInfoMap[fromIx].fromFlows += flows;
            if (!regionsInfoMap[toIx]) {
              regionsInfoMap[toIx] = { index: toIx, toFlows: 0, fromFlows: 0 };
            }
            regionsInfoMap[toIx].toFlows += flows;
          }
        });
      },
    );
    const regionsInfo = Object.values(regionsInfoMap);
    regionsInfo.forEach((r: any) => {
      r.total = r.fromFlows + r.toFlows;
      r.name = regionLabels[r.index] || 'External';
    });
    const newSortedRegionsInfo = [...regionsInfo].sort(
      (a: any, b: any) => b.total - a.total,
    );
    sortedRegionsInfo.current = newSortedRegionsInfo;
    flowSlice.forEach(
      (
        row: { forEach: (arg0: (flows: any, toIx: any) => void) => void },
        fromIx: number,
      ) => {
        row.forEach((flows: number, toIx: number) => {
          if (
            isVisible(fromIx, toIx, fromLength, toLength) &&
            flows > flowLimit
          ) {
            if (fromIx === sliceIndex || toIx === sliceIndex) {
              return;
            }
            if (
              state.allowedFrom.indexOf(fromIx) < 0 ||
              state.allowedTo.indexOf(toIx) < 0
            ) {
              return;
            }
            const fromLabel = getChartLabel(fromIx, fromLength, true);
            const toLabel = getChartLabel(toIx, toLength, false);
            chartRows.push([fromLabel, toLabel, flows]);
          }
        });
      },
    );
    return chartRows;
  };

  const renderListItem = (r: { index: number; name: string }, i: number) => {
    const isInFromList = state.allowedFrom.includes(r.index);
    const isInToList = state.allowedTo.includes(r.index);
    const addToStateList = (key: string) => () =>
      setState(
        (state: any) =>
          ({
            ...state,
            [key]: [...state[key], r.index],
          } as any),
      );
    const removeFromStateList = (key: string) => () => {
      setState(
        (state: any) =>
          ({
            ...state,
            [key]: state[key].filter((ind: any) => ind !== r.index),
          } as any),
      );
    };
    const addToFromList = addToStateList('allowedFrom');
    const addToToList = addToStateList('allowedTo');
    const removeFromFromList = removeFromStateList('allowedFrom');
    const removeFromToList = removeFromStateList('allowedTo');
    return (
      <li className="Sankey-list-item" key={i}>
        {r.name}
        <div
          className={`Sankey-list-item-button ${
            isInFromList ? 'Sankey-list-item-button--active' : ''
          }`}
          onClick={() => {
            !isInFromList ? addToFromList() : removeFromFromList();
          }}
          style={{ marginLeft: 'auto' }}
        >
          From
        </div>
        <div
          className={`Sankey-list-item-button  ${
            isInToList ? 'Sankey-list-item-button--active' : ''
          }`}
          onClick={() => {
            !isInToList ? addToToList() : removeFromToList();
          }}
        >
          To
        </div>
        <div
          className="Sankey-list-item-delete"
          onClick={() =>
            setState((state) => ({
              ...state,
              displayRegions: state.displayRegions.filter(
                (region: any) => r.index !== region.index,
              ),
              allowedFrom: state.allowedFrom.filter(
                (index) => r.index !== index,
              ),
              allowedTo: state.allowedTo.filter((index) => r.index !== index),
            }))
          }
        >
          ✕
        </div>
      </li>
    );
  };

  const { sliceIndex, showInternals, flowLimit, displayRegions } = state;
  const displayRegionsIndexes = displayRegions.map((r) => r.index);

  const { scenario, setScenario } = useScenario();

  const allRegions = useMemo(() => {
    const regionIds = analysis.regions.map((_, i) => i);
    return hasExternals ? [...regionIds, analysis.regions.length] : regionIds;
  }, [analysis.regions, hasExternals]);

  const scenarioWithRegions: Scenario = useMemo(
    () => ({
      ...scenario,
      origins: Immutable.Set(allRegions),
      destinations: Immutable.Set(allRegions),
      vias: Immutable.Set(state.sliceIndex !== null ? [state.sliceIndex] : []),
    }),
    [scenario, state.sliceIndex],
  );

  const { result, loading } = PartialResult.use(analysis, scenarioWithRegions);

  const chartRows = generateChartRows(result);

  // Select some regions on the start
  if (!initialRegionsSet.current && !loading) {
    const length = Math.min(5, sortedRegionsInfo.current.length);
    const getIndex = (region: any) => region.index;
    // Use machine learning to get interesting regions
    const topRegions = sortedRegionsInfo.current.map(getIndex).slice(1, length);
    setTimeout(() => {
      setState({
        ...state,
        displayRegions: sortedRegionsInfo.current.slice(1, length),
        allowedFrom: topRegions,
        allowedTo: topRegions,
      });
      initialRegionsSet.current = true;
    }, 0);
  }

  return (
    <>
      <ViewPageNavbar visualisationName="Sankey Diagram" />
      <ViewPageContent>
        <MapAside width="300px">
          <MapAsideSection title="Regions" flex noShrink defaultHide={false}>
            <SankeySearch
              list={sortedRegionsInfo.current.filter(
                (r: { index: any }) =>
                  displayRegionsIndexes.indexOf(r.index) < 0,
              )}
              onSearch={(r: { index: undefined }) =>
                setState((state: any) => ({
                  ...state,
                  displayRegions: [...state.displayRegions, r],
                  allowedFrom: [...state.allowedFrom, r.index],
                  allowedTo: [...state.allowedTo, r.index],
                }))
              }
            />
            <ul className="Sankey-list">
              {displayRegions && displayRegions.map(renderListItem)}
            </ul>
          </MapAsideSection>
          <DateTimeSelector
            column
            asideSection
            analysis={analysis}
            scenario={scenario}
            onChange={setScenario}
          />
          <MapAsideSection title="Via region">
            <ThroughRegionSelect
              regionLabels={regionLabels}
              sliceIndex={sliceIndex}
              changeSlice={(sliceIndex: any) =>
                setState((s) => ({ ...s, sliceIndex }))
              }
            />
          </MapAsideSection>
          <MapAsideSection title="Lower flow limit">
            <Input
              defaultValue={'' + flowLimit}
              type="number"
              ref={(c) => (flowInput.current = c)}
              $width="100%"
            />
            <Button
              className="Sankey-limit-button"
              onClick={() =>
                setState({
                  ...state,
                  flowLimit: flowInput.current.value,
                })
              }
            >
              Apply
            </Button>
          </MapAsideSection>
          <MapAsideSection title="Controls">
            <Checkbox
              label="Internal flows"
              checked={showInternals}
              onChange={() =>
                setState((s) => ({ ...s, showInternals: !s.showInternals }))
              }
            />
          </MapAsideSection>
        </MapAside>
        <div className="sankey-chart">
          {loading && <ResultSpinner subtitle="Loading results..." map />}
          <SankeyChart
            sliceIndex={sliceIndex}
            changeSlice={(sliceIndex: any) =>
              setState((s) => ({ ...s, sliceIndex }))
            }
            getChartLabel={getChartLabel}
            chartRows={chartRows}
            loading={loading}
          />
        </div>
      </ViewPageContent>
    </>
  );
}
export default SankeyDiagram;
